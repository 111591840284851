import styled from '@emotion/styled'
import Link from 'next/link'
import React from 'react'

import { MonoButton } from '@/clients/components/atoms/Button'
import { useViewEvent } from '@/clients/firebase/analytics'
import { colors } from '@/clients/styles/theme'
import { Footer } from '@/clients/components/molecules/Footer'
import { GlobalNav } from '@/components/organisms/GlobalNav'
import { AppBanner } from '@/clients/components/organisms/Banner/AppBanner'
import { GetServerSideProps } from 'next'

type Props = {}

const NotFound: React.FC<Props> = () => {
  useViewEvent('404View')
  return (
    <LayoutContainer>
      <GlobalNav />
      <AppBanner />
      <Container>
        <Title>_(┐「ε:)_ｽﾞｺｰ</Title>
        <Description>何かがうまくいかなかったようです。。。。</Description>
        <Link href="/" locale={'ja'}>
          <BackButton>トップへ戻る</BackButton>
        </Link>
      </Container>
      <Footer />
    </LayoutContainer>
  )
}

export default NotFound

const LayoutContainer = styled.div`
  width: 100%;
  color: ${colors.bg.primary};
`

const Container = styled.div`
  height: 50vh;
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Title = styled.h1`
  font-weight: bold;
  text-align: center;
  font-size: 32px;
  color: ${colors.text.primary};
`

const Description = styled.div`
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  color: ${colors.text.primary};
  margin-top: 16px;
  line-height: 2;
`

const BackButton = styled(MonoButton)`
  margin-top: 32px;
  padding: 16px 32px;
`
